/* General styles */
*{margin:0;padding:0;box-sizing:border-box;font-family:'Roboto',sans-serif;font-weight:400;color:#fff;}
img, video, iframe, picture{display:block;}
body{background-color:#000;}
.section{position:relative;}
.content{position:relative;width:100%;max-width:1400px;margin:auto;}
.flex_content{display:flex;flex-wrap:wrap;}
.content_half{width:50%;}
.title{font-family:'Baskervville',serif;}

/* Navigation bar */
.navbar{position:absolute;width:100%;height:auto;left:0;top:0;z-index:3;}
.navbar .content{padding-top:1rem;padding-bottom:1rem;display:flex;align-items:center;}
.navbar_logo img{width:8rem;}
.navbar_links{display:flex;justify-content:flex-end;flex-grow:1;}
.navbar_links__el{text-decoration:none;margin-right:1rem;}
.navbar_links__el:last-child{margin-right:0;}

/* Floating menu */
.floating_menu_flag.show{opacity:1;}
.entradas.active{transform:translateY(417%);}
.platillos.active{transform:translateY(62%);}
.bebidas.active{transform:translateY(0);}

/* Footer */
.footer .content{padding-bottom:0;padding-top:2rem;display:flex;}
.footer_el{width:25%;}
.footer_el__title{margin-bottom:1rem;}
.footer_el__link{display:block;margin-bottom:.5rem;text-decoration:none;}
.footer_el__des{margin-bottom:1rem;}
.footer_el__logo{width:8rem;margin-bottom:1rem;}
.footer_el__doble{display:flex;align-items:center;text-decoration:none;}
.footer_el__doble:nth-child(2){margin-bottom:1rem;}
.footer_el__doble img{width:1rem;height:auto;margin-right:1rem;}
.footer_el_social{position:relative;width:fit-content;}
.footer_el_social img{width:7.5rem;}
.footer_el_social a{position:absolute;top:0;left:0;width:2.5rem;height:100%;}
.footer_el_social a:nth-child(3){left:2.5rem;}
.footer_el_social a:nth-child(4){left:5rem;}

/* Copyright */
.copyright .content{padding-bottom:6rem;padding-top:4rem;text-align:center;}

/* Responsive style */
@media (max-width: 599px){
    .navbar .content{flex-direction:column;}
    .navbar_logo{margin-bottom:.5rem;}

    .content{padding:3rem 1rem;}
    .content_half{width:100%;}

    .footer .content{flex-wrap:wrap;}
    .footer_el:nth-child(1){width:100%;margin-bottom:2rem;}
    .footer_el__logo{margin:auto;margin-bottom:2rem;}
    .footer_el:nth-child(2),.footer_el:nth-child(3){width:calc(50% - 2rem);margin-right:2rem;}
    .footer_el:nth-child(4){width:100%;}
    .footer_el_social{margin:auto;margin-top:1rem;}
    .footer_el__title{font-size:1.2rem;}
    .footer_el__link, .footer_el__des{font-size:1rem;}

    .copyright .content{padding-top:3rem;padding-bottom:5rem;}
}

@media (max-width:899px) and (min-width: 600px){
    .content{padding:4rem 2rem;}
    .content_half{width:100%;}

    .navbar_links__el{font-size:1.2rem;}

    .footer .content{flex-wrap:wrap;}
    .footer_el:nth-child(1){width:100%;margin-bottom:2rem;}
    .footer_el:nth-child(2),.footer_el:nth-child(3), .footer_el:nth-child(4){width:calc(33.3% - 2rem);margin-right:2rem;}
    .footer_el__title{font-size:1.5rem;}
    .footer_el__link, .footer_el__des{font-size:1.1rem;}
}

@media (max-width:1199px) and (min-width: 900px){
    .content{padding:4rem 3rem;}

    .navbar_links__el{font-size:1.2rem;}

    .footer_el{padding-right:2rem;width:20%;}
    .footer_el:nth-child(1){width:40%;}
    .footer_el:nth-child(2),.footer_el:nth-child(4){width:15%;}
    .footer_el:nth-child(3){width:25%;}
    .footer_el__title{font-size:1.5rem;}
    .footer_el__link, .footer_el__des{font-size:1.1rem;}
}

@media (max-width:1399px) and (min-width: 1200px){
    .content{padding:4rem;}

    .navbar_links__el{font-size:1.2rem;}

    .footer_el{padding-right:1rem;}
    .footer_el__title{font-size:1.5rem;}
    .footer_el__link, .footer_el__des{font-size:1.1rem;}
}

@media (min-width:1400px){
    .content{padding:4rem;}

    .navbar_links__el{font-size:1.3rem;}

    .footer_el{padding-right:2rem;}
    .footer_el__title{font-size:1.8rem;}
    .footer_el__link, .footer_el__des{font-size:1.2rem;}
}