/* General */
.button{display:block;width:fit-content;padding:.8rem 2.5rem;border:.1rem solid #fff;font-family:'Baskervville',serif;text-decoration:none;margin-top:2rem;position:relative;overflow:hidden;transition:all .5s ease;}
.button span{position:absolute;top:0;left:0;width:100%;height:100%;background-color:#fff;transform:translateX(-100%);transition:all .5s ease;z-index:-1;}
.button:hover{color:#000;}
.button:hover span{transform:translateX(0);}
.title{font-family:'Baskervville',serif;}

/* Header */
.header, .header_bg{width:100%;height:auto;position:relative;}
.header_shadow{position:absolute;bottom:0;left:0;width:100%;z-index:3;}

/* Esta vida me encanta */
.vida_content{align-items:center;}
.vida_img img{width:100%;height:auto;}
.vida_des__sep{width:8rem;height:.1rem;background-color:#fff;margin-bottom:2rem;}
.vida_des__t1{margin-bottom:1.5rem;}
.vida_des__t2{margin-bottom:1rem;text-align:justify;}

/* Menu */
.menu_content{text-align:center;}
.menu__t1{font-family:'Baskervville',serif;font-style:italic;}
.menu__t2{margin-bottom:.5rem;}
.menu__t3{max-width:500px;margin:auto;margin-bottom:2rem;}
.menu_con, .menu_con__bg{width:100%;height:auto;position:relative;}
.menu_con__link{position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);z-index:2;}

/* Cocktail */
.cocktail_content{padding-top:0;align-items:center;}
.cocktail_img img{width:100%;height:auto;}
.cocktail_des__t1{margin-bottom:1.5rem;}

/* Redes */
.redes__t1{font-family:'Baskervville',serif;font-style:italic;text-align:center;margin-bottom:1.5rem;}
.redes_con{display:flex;flex-wrap:wrap;position:relative;}
.redes_con__link{position:absolute;top:0;left:0;width:100%;height:100%;z-index:2;}
.redes_con img{width:calc(33% - .5rem);}
.redes_con img:nth-child(1){margin-right:.5rem;}
.redes_con img:nth-child(2){margin-right:.25rem;margin-left:.25rem;}
.redes_con img:nth-child(3){margin-left:.5rem;}

/* Responsive style */
@media (max-width: 599px){
    .button{font-size:1.2rem;letter-spacing:2px;}
    .title{font-size:2rem;letter-spacing:.2rem;line-height:2.2rem;}
    .des{font-size:1rem;line-height:1.6rem;}

    .vida_content{flex-direction:column-reverse;}
    .vida_des__sep{margin:auto;margin-bottom:2rem;}
    .vida_des{margin-bottom:2rem;text-align:center;}
    .vida_des__t2{text-align:center;margin-bottom:.5rem;}
    .vida_des__btn{margin:auto;margin-top:1.5rem;}

    .menu_content{padding-top:2rem;}
    .menu__t1{font-size:1.2rem;}
    .menu__t3{font-size:1rem;line-height:1.6rem;}
    .menu_con__link{margin-top:0;white-space:nowrap;}

    .cocktail_content{padding-top:2rem;}
    .cocktail_des{text-align:center;}
    .cocktail_des__t2{margin-bottom:2rem;}

    .redes__t1{font-size:2rem;}
    .redes_con img{width:calc(50% - .25rem);}
    .redes_con img:nth-child(1){margin-right:.25rem;margin-left:0;}
    .redes_con img:nth-child(2){margin-left:.25rem;margin-right:0;}
    .redes_con img:nth-child(3){display:none;}
}

@media (max-width:899px) and (min-width: 600px){
    .button{font-size:1.1rem;letter-spacing:2px;}
    .title{font-size:2.2rem;letter-spacing:.2rem;line-height:2.2rem;}
    .des{font-size:1.1rem;line-height:1.6rem;}

    .vida_content{flex-direction:column-reverse;}
    .vida_des__sep{margin:auto;margin-bottom:2rem;}
    .vida_des{margin-bottom:2rem;text-align:center;}
    .vida_des__t2{max-width:500px;text-align:center;margin:auto;margin-bottom:.5rem;}
    .vida_des__btn{margin:auto;margin-top:1.5rem;}

    .menu_content{padding-top:2rem;}
    .menu__t1{font-size:1.3rem;}
    .menu__t3{font-size:1.1rem;line-height:1.6rem;}
    .menu_con__link{margin-top:0;}

    .cocktail_content{padding-top:2rem;}
    .cocktail_des{text-align:center;}
    .cocktail_des__t2{max-width:500px;margin:auto;margin-bottom:2rem;}

    .redes__t1{font-size:2.2rem;}
    .redes_con img{width:calc(50% - .5rem);}
    .redes_con img:nth-child(1){margin-right:.5rem;margin-left:0;}
    .redes_con img:nth-child(2){margin-left:.5rem;margin-right:0;}
    .redes_con img:nth-child(3){display:none;}
}

@media (max-width:1199px) and (min-width: 900px){
    .button{font-size:1.1rem;letter-spacing:2px;}
    .title{font-size:2rem;letter-spacing:.2rem;line-height:2.2rem;}
    .des{font-size:1.1rem;line-height:1.8rem;}

    .vida_img{padding:1rem 2rem 1rem 0;}
    .vida_des__t2{max-width:440px;}

    .menu__t1{font-size:1.3rem;}
    .menu__t3{font-size:1.1rem;}

    .cocktail_des{padding-right:2rem;}

    .redes__t1{font-size:2rem;}
}

@media (max-width:1399px) and (min-width: 1200px){
    .button{font-size:1.1rem;letter-spacing:2px;}
    .title{font-size:2.2rem;letter-spacing:.2rem;}
    .des{font-size:1.1rem;line-height:1.8rem;}

    .vida_img{padding:1rem 4rem 1rem 0;}
    .vida_des__t2{max-width:440px;}

    .menu__t1{font-size:1.2rem;}
    .menu__t3{font-size:1.1rem;}

    .cocktail_des{padding-right:4rem;}

    .redes__t1{font-size:2.2rem;}
}

@media (min-width:1400px){
    .button{font-size:1.3rem;letter-spacing:2px;}
    .title{font-size:2.5rem;letter-spacing:.2rem;}
    .des{font-size:1.2rem;line-height:1.9rem;}

    .vida_img{padding:1rem 4rem 1rem 0;}
    .vida_des__t2{max-width:500px;}

    .menu__t1{font-size:1.3rem;}
    .menu__t3{font-size:1.2rem;}

    .cocktail_des{padding-right:6rem;}

    .redes__t1{font-size:2.5rem;}
}