/* Header */
.header{width:100%;height:auto;}

/* Menu */
.menu_content{display:flex;flex-wrap:wrap;}
/*.menu_content:nth-child(even){flex-direction:row-reverse;}*/
.menu_content.reverse{flex-direction:row-reverse;}
.menu_title{width:100%;text-align:center;margin-bottom:3rem;}
.menu_title_des{display:block;width:100%;text-align:center;max-width:750px;margin:auto;font-size:1.2rem;margin-top:2rem;}
.menu_image{width:100%;height:auto;}
.menu_disclaimer{width:100%;text-align:center;max-width:750px;margin:auto;}

/* Platillo */
.platillo{margin-bottom:3rem;}
.platillo_title{display:flex;}
.platillo_nombre{flex-grow:1;margin-bottom:.5rem;}
.platillo_nombre span{margin-left:.5rem;}
.platillo_des{padding-right:2rem;}

/* Platillo multiple */
.platillo_des_multiple__el{display:flex;margin-bottom:.2rem;}
.platillo_des_multiple__el__nombre{flex-grow:1;}

/* Bebida title */
.bebida_title_con{width:100%;display:flex;margin-bottom:1.5rem;align-items:flex-end;}
.bebida_title_nombre{flex-grow:1;}
.bebida_title_botella, .bebida_title_copa{width:10%;text-align:center;}
.bebida_title_botella span, .bebida_title_copa span{display:block;}

/* Bebida */
.bebidas_container{width:100%;margin-bottom:2rem;}
.bebidas_container:last-child{margin-bottom:0;}
.bebida{margin-bottom:.5rem;display:flex;align-items:center;flex-wrap:wrap;}
.bebida_nombre{flex-grow:1;}
.bebida_nombre span{margin-left:.5rem;}
.bebida_botella, .bebida_copa{width:10%;text-align:center;}
.bebida_des{width:80%;}

/* Floating menu */
.floating_menu_con{position:fixed;bottom:0;left:0;width:100%;display:flex;flex-direction:column;opacity:0;transition:all .5s ease;}
.floating_menu{background-color:#fff;padding:1rem;display:flex;justify-content:center;z-index:2;}
.floating_menu__el{text-decoration:none;color:#000;margin-right:1rem;cursor:pointer;}
.floating_menu__el:nth-child(4){margin-right:0;}
.floating_submenu{background-color:#fff;padding:1rem;transition:all .5s ease;}

.floating_submenu.entradas{transform:translateY(516%);}
.floating_submenu.platillos{transform:translateY(200%);}
.floating_submenu.bebidas{transform:translateY(100%);}

.floating_sub_el{display:block;text-decoration:none;color:#000;margin-bottom:.5rem;}
.floating_sub_el:last-child{margin-bottom:0;}

/* Responsive style */
@media (max-width: 599px){
    .menu_title{font-size:2rem;letter-spacing:4px;}

    .platillo_nombre{font-size:1.2rem;padding-right:2rem;}
    .platillo_nombre span, .platillo_des{font-size:1rem;}
    .platillo_des{padding-right:3rem;}

    .bebida_title_nombre{font-size:1.3rem;}
    .bebida_title_botella, .bebida_title_copa, .bebida_botella, .bebida_copa{width:20%;font-size:.8rem;}

    .bebida_nombre{font-size:.9rem;width:60%;}
    .bebida_nombre span{font-size:.7rem;}

    .floating_menu__el{font-size:1rem;margin-right:1rem;}
    .floating_sub_el{font-size:1.1rem;}
}

@media (max-width:899px) and (min-width: 600px){
    .menu_title{font-size:2.2rem;letter-spacing:4px;}

    .menu_section{padding:0 1rem;}

    .platillo_nombre{font-size:1.3rem;}
    .platillo_nombre span, .platillo_des{font-size:1.1rem;}
    .platillo_des{padding-right:6rem;}

    .bebida_title_nombre{font-size:1.8rem;}
    .bebida_title_botella, .bebida_title_copa, .bebida_botella, .bebida_copa{width:6rem;font-size:.9rem;}

    .bebida_nombre{font-size:1.1rem;}
    .bebida_nombre span{font-size:.9rem;}

    .floating_menu__el{font-size:1.2rem;}
    .floating_sub_el{font-size:1.2rem;}
    .floating_submenu{padding:1rem 2rem;}
}

@media (max-width:1199px) and (min-width: 900px){
    .menu_title{font-size:2.3rem;letter-spacing:4px;}

    .menu_section:nth-child(2){padding-right:2rem;}
    .menu_section:nth-child(3){padding-left:2rem;}
    .menu_content.reverse .menu_section:nth-child(2){padding-left:2rem;padding-right:0;}
    .menu_content.reverse .menu_section:nth-child(3){padding-right:2rem;padding-left:0;}

    .platillo_nombre{font-size:1.2rem;}
    .platillo_nombre span, .platillo_des{font-size:.9rem;}

    .bebida_title_nombre{font-size:2.2rem;}
    .bebida_title_botella, .bebida_title_copa, .bebida_botella, .bebida_copa{width:10rem;font-size:1.1rem;}

    .bebida_nombre{font-size:1.2rem;}
    .bebida_nombre span{font-size:1rem;}

    .floating_menu__el{font-size:1.2rem;margin-right:1.5rem;}
    .floating_sub_el{font-size:1.2rem;}
    .floating_submenu{padding:1rem 3rem;}
}

@media (max-width:1399px) and (min-width: 1200px){
    .menu_title{font-size:2.5rem;letter-spacing:5px;}

    .menu_section:nth-child(2){padding-right:2rem;}
    .menu_section:nth-child(3){padding-left:2rem;}
    .menu_content.reverse .menu_section:nth-child(2){padding-left:2rem;padding-right:0;}
    .menu_content.reverse .menu_section:nth-child(3){padding-right:2rem;padding-left:0;}

    .platillo_nombre{font-size:1.2rem;}
    .platillo_nombre span, .platillo_des{font-size:.9rem;}

    .bebida_title_nombre{font-size:2rem;}
    .bebida_title_botella, .bebida_title_copa, .bebida_botella, .bebida_copa{width:10rem;}

    .bebida_nombre{font-size:1.1rem;}
    .bebida_nombre span{font-size:.9rem;}

    .floating_menu__el{font-size:1.2rem;margin-right:1.5rem;}
    .floating_sub_el{font-size:1.2rem;}
    .floating_submenu{padding:1rem 4rem;}
}

@media (min-width:1400px){
    .menu_title{font-size:3rem;letter-spacing:5px;}

    .menu_section:nth-child(2){padding-right:3rem;}
    .menu_section:nth-child(3){padding-left:3rem;}
    .menu_content.reverse .menu_section:nth-child(2){padding-left:3rem;padding-right:0;}
    .menu_content.reverse .menu_section:nth-child(3){padding-right:3rem;padding-left:0;}

    .platillo_nombre{font-size:1.5rem;}
    .platillo_nombre span, .platillo_des{font-size:1.1rem;}
    .platillo_des{padding-right:4rem;}

    .bebida_title_nombre{font-size:2.2rem;}
    .bebida_title_botella, .bebida_title_copa, .bebida_botella, .bebida_copa{width:12rem;font-size:1.1rem;}

    .bebida_nombre{font-size:1.2rem;}
    .bebida_nombre span{font-size:1rem;}

    .floating_menu__el{font-size:1.2rem;margin-right:1.5rem;}
    .floating_sub_el{font-size:1.2rem;}
    .floating_submenu{padding:1rem 4rem;}
}